import '@fortawesome/fontawesome-free/js/all';
// import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'lazyload/lazyload';
import MicroModal from 'micromodal/dist/micromodal';

import '../src/top/styles/top.scss';

('use strict');

// スライダー（トップ）
jQuery(function ($: any) {
  if ($('.slick').length) {
    $('.slick').slick({
      autoplay: true,
      dots: true,
      arrows: false,
      speed: 1000,
      infinite: true,
      fade: true,
    });
  }
});

// スライダー（先生紹介）
jQuery(function ($: any) {
  if (jQuery('.teacher_slick').length) {
    const slider = '.teacher_slick'; // スライダー
    const thumbnailItem = '.teacher_slick_thumbnail li'; // サムネイル画像アイテム

    // サムネイル画像アイテムに data-index でindex番号を付与
    $(thumbnailItem).each(function (this: any) {
      const index = $(thumbnailItem).index(this);
      $(this).attr('data-index', index);
    });

    // スライダー初期化後、カレントのサムネイル画像にクラス「thumbnail-current」を付ける
    // 「slickスライダー作成」の前にこの記述は書いてください。
    $(slider).on('init', function (slick: any) {
      const index = $('.slide-item.slick-slide.slick-current').attr(
        'data-slick-index',
      );
      $(thumbnailItem + '[data-index="' + index + '"]').addClass(
        'thumbnail-current',
      );
    });

    //slickスライダー初期化
    $(slider).slick({
      autoplay: false,
      arrows: true,
      fade: true,
      infinite: true,
    });
    //サムネイル画像アイテムをクリックしたときにスライダー切り替え
    $(thumbnailItem).on('click', function (this: any) {
      const index = $(this).attr('data-index');
      $(slider).slick('slickGoTo', index, false);
    });

    //サムネイル画像のカレントを切り替え
    $(slider).on(
      'beforeChange',
      function (event: any, slick: any, currentSlide: any, nextSlide: any) {
        $(thumbnailItem).each(function (this: any) {
          $(this).removeClass('thumbnail-current');
        });
        $(thumbnailItem + '[data-index="' + nextSlide + '"]').addClass(
          'thumbnail-current',
        );
      },
    );
  }
});

// フェードイン
jQuery(function ($: any) {
  $(window).scroll(function () {
    $('.fadein').each(function (this: any) {
      const targetElement = $(this).offset().top;
      const scroll = $(window).scrollTop();
      const windowHeight = $(window).height();
      if (scroll > targetElement - windowHeight + 100) {
        $(this).css('opacity', '1');
        $(this).css('transform', 'translate(0)');
      }
    });
  });
});

// 遅延ロード
jQuery(function () {
  (window as any).lazyload();
});

// テキストアニメーション
jQuery(function ($: any) {
  $(window).scroll(function () {
    $('.sentence').each(function (this: any) {
      const targetElement = $(this).offset().top;
      const scroll = $(window).scrollTop();
      const windowHeight = $(window).height();
      if (scroll > targetElement - windowHeight + 100) {
        textAnimation();
      }
    });
  });
});
function textAnimation() {
  const CLASSNAME = '-visible';
  const DELAY1 = 100;
  const DELAY2 = 200;
  const DELAY3 = 300;
  const DELAY4 = 400;
  const DELAY5 = 500;
  const DELAY6 = 600;
  const $target1 = $('.sentence1');
  const $target2 = $('.sentence2');
  const $target3 = $('.sentence3');
  const $target4 = $('.sentence4');
  const $target5 = $('.sentence5');
  const $target6 = $('.sentence6');
  const $target7 = $('.sentence7');

  $target1.addClass(CLASSNAME);
  setTimeout(() => {
    $target2.addClass(CLASSNAME);
  }, DELAY1);
  setTimeout(() => {
    $target3.addClass(CLASSNAME);
  }, DELAY2);
  setTimeout(() => {
    $target4.addClass(CLASSNAME);
  }, DELAY3);
  setTimeout(() => {
    $target5.addClass(CLASSNAME);
  }, DELAY4);
  setTimeout(() => {
    $target6.addClass(CLASSNAME);
  }, DELAY5);
  setTimeout(() => {
    $target7.addClass(CLASSNAME);
  }, DELAY6);
}

// ワイドアニメーション
jQuery(function ($: any) {
  $(window).scroll(function () {
    $('.top_lesson_item').each(function (this: any) {
      const targetElement = $(this).offset().top;
      const scroll = $(window).scrollTop();
      const windowHeight = $(window).height();
      if (scroll > targetElement - windowHeight + 100) {
        $(this).addClass('isShow');
      }
    });
  });
});

// セルフグレードチェック
jQuery(function ($: any) {
  if ($('.grade_check_item').length) {
    $('.btn_answer').on('click', function (this: any) {
      $('.grade_check').toggleClass('grade_check_even');
      jQuery('.grade_check_item_show')
        .removeClass('grade_check_item_show')
        .hide();
      const btnClass = $(this).attr('class');
      const btnClassArray = btnClass.split(' ');
      $('#' + btnClassArray[1])
        .fadeIn()
        .addClass('grade_check_item_show');
    });
    $('.btn_beginner').on('click', function () {
      $('.grade_check').removeClass('grade_check_even');
    });
  }
});

// URLにアンカーが存在する場合
const urlHash = location.hash;
const headerHeight = $('.header').height();
if (urlHash) {
  setTimeout(function () {
    const position = $(urlHash).offset().top - headerHeight;
    $('html, body').animate(
      {
        scrollTop: position,
      },
      400,
      'swing',
    );
  }, 500);
}

// アコーディオンとモーダル
$(function () {
  $('.qa_signup_item > dt').on('click', function (this: any) {
    $(this).next().slideToggle();
    $(this).toggleClass('qa_acc_open');
  });
  if ($('.lesson_curriculum').length) {
    MicroModal.init({
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }
  // このサービスをどこで知りましたか？ 「その他」を選んだ場合、テキストエリアが表示される
  $('select.form-control').on('change', function (this: any) {
    const val = $(this).val();
    if (val == 6) {
      $(this).next().removeClass('d-none');
    } else {
      $(this).next().addClass('d-none');
    }
  });
});

// フォームバリデーション
$(function () {
  if ($('#form').length) {
    // フォームバリデーションチェック
    $('#chk').submit(function () {
      // 初期化
      $('p.error').remove();
      // Validation対象のクラス名
      const obj = $('.require');
      let ret = true;
      const bgColor = 'pink';
      const parentsName = 'dd';

      $.each(obj, function (this: any) {
        const val = $(this).val();
        if (!val) {
          $(this).css('background-color', bgColor);
          ret = false;
          if (!$(this).parents(parentsName).children('.error').length) {
            $(this)
              .parents(parentsName)
              .prepend("<p class='error'>※必須項目です</p>");
          }
          //miss = "未入力の項目があります。"
        } else {
          $(this).css('background-color', 'white');
        }

        if ($(this).attr('id') == 'mail') {
          if ($(this).val() && !($(this).val() as string).match(/.+@.+\..+/g)) {
            $(this).css('background-color', bgColor);
            ret = false;
            $(this)
              .parents(parentsName)
              .prepend(
                "<p class='error'>※正しいメールアドレスを入力してください</p>",
              );
            //miss = "正しいメールアドレスを入力してください。"
          }
        }
      });

      // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
      if (ret === false) {
        $('body,html').animate(
          { scrollTop: $('.error:first').offset().top - 90 },
          800,
          'swing',
        );
        return false;
      } else {
        return true;
      }
    });
  }
});

// confirm画面
$(function () {
  if ($('#confirm').length) {
    const $onOff = $('.on_off'),
      $noChange = 'none';
    $onOff.hover(
      function (this: any) {
        $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
      },
      function (this: any) {
        if (!$(this).hasClass($noChange)) {
          $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
        }
      },
    );
    const $backBtn = $('#prevBtn');
    $backBtn.on('click', function () {
      history.back();
    });
  }
});
